<template>
  <exterior-page-content-container>
    <div class="flex flex-col items-center h-64">
      <p class='mb-3 text-2xl font-bold text-center text-primary'>Forgot Password</p>

      <notification-banner
        v-if="error.length > 0"
        color="red"
      >
        {{ error }}
      </notification-banner>

      <notification-banner
        v-if="responseMessage.length > 0"
        color="gray"
      >
        {{responseMessage}}
      </notification-banner>

      <text-input-component
        v-show="!requestSent"
        :value.sync="email"
        placeholder="email@example.com"
        :valid="true"
        label="Email"
        mode="form"
        class="mt-2"
      />
      <button-large
        v-show="!requestSent"
        class='mt-5'
        @click="resetPassword"
        color="green"
        :disabled="!isValidEmail(email)"
      >
        Reset Password
      </button-large>

      <p
        class="mt-8 text-content text-bold"
      >
        <a
          class="underline cursor-pointer text-secondary"
          @click="$router.push({name: 'SignIn'})"
        >
          Back to Sign In
        </a>
      </p>

    </div>
  </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';
import NotificationBanner from '@/components/shared/general/NotificationBanner.vue';

import { USER_FORGOT_PASSWORD } from '@/store/actions';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import { emailValidation } from '@/utils/validators';
import ButtonLarge from '../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'ForgotPassword',
  components: {
    ExteriorPageContentContainer,
    NotificationBanner,
    TextInputComponent,
    ButtonLarge,
  },
  data() {
    return {
      email: '',
      requestSent: false,
      responseMessage: '',
      error: '',
    };
  },
  methods: {
    resetPassword() {
      if (this.isValidEmail(this.email)) {
        this.$store.dispatch(USER_FORGOT_PASSWORD, this.email.trim())
          .then((response) => {
            this.requestSent = true;
            this.email = '';
            this.error = '';
            this.responseMessage = response;
          })
          .catch((error) => {
            this.requestSent = true;
            this.email = '';
            this.error = '';
            this.responseMessage = error.response.data;
          });
      } else {
        this.error = 'Please enter a valid email';
      }
    },
    isValidEmail(email) {
      return emailValidation(email.trim());
    },

  },
};
</script>
