<template>
  <div class="`
    flex flex-col items-center justify-between
    h-full w-full
    overflow-y-auto
  `">
    <div :class="`
        flex flex-col items-center justify-between
        h-full w-full
        bg-center bg-cover bg-main-pic
      `"
    >
      <div class="`
        flex justify-start self-end flex-shrink-0 pt-2 mb-3 w-full
      `">

        <div class="p-3 mb-2 bg-gray-300 rounded bg-opacity-30 ml-7">
          <img
            src="/img/logos/KIV-logo-transparent.png"
            alt="Kept In View Logo"
            class="w-24"
          >
        </div>
      </div>

      <div class="flex flex-col items-center flex-shrink-0 w-full p-5 rounded-t-lg bg-backfill">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExteriorPageContentContainer',

};
</script>
