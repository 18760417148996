<template>
  <div
    :class="`
      flex flex-col items-stretch bg-${color}-100 border border-${color}-400 rounded-lg
      overflow-hidden

      notification-banner
    `">
    <div
      :class="`px-4 py-3 text-${color}-700`"
      role="alert"
    >
      <slot/>
    </div>
    <div :class="`border-2 border-${color}-400`" :style="`width: ${width}%;`"></div>
  </div>
</template>

<script>
export default {
  name: 'NotificationBanner',
  props: {
    color: {
      type: String,
      required: true,
      validator: (val) => ['green', 'red', 'gray'].includes(val),
    },
    timeout: {
      type: Number,
      required: false,
      default: 0,
    },
    showNotification: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      width: 0,
    };
  },
  watch: {
    showNotification(val) {
      if (val === true) {
        this.width = 0;
        this.activateBar();
      }
    },
  },
  methods: {
    activateBar() {
      if (this.timeout && this.timeout > 0 && this.width === 0) {
        const increment = 1;
        const timeout = setInterval(() => {
          if (this.width >= 100) {
            this.$emit('hide-notification');
            clearTimeout(timeout);
            this.width = 0;
          } else {
            this.width += (100 / this.timeout) * 7;
          }
        }, increment);
      }
    },
  },
};
</script>
